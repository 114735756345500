import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { SearchPrintSponsorDoc, GetPlans } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/get-plan.model';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { alertMessages } from 'src/app/helpers/common-constants';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'print-sponsor-doc',
  templateUrl: './print-sponsor-doc.component.html',
  styleUrls: ['./print-sponsor-doc.component.css'],
})
export class PrintSponsorDocComponent implements OnInit {
  planNumber: string;
  planLabel: string;
  searchPrintSponsorDocs: SearchPrintSponsorDoc[];
  loading = false;
  createDoc = false;
  errorMessage: NfrpAlertMessage;
  infoMessage: NfrpAlertMessage;
  successMessage: NfrpAlertMessage;
  planId: string;
  userEmail: string;
  planDetails: GetPlans;
  isPending: boolean;
  private subscriptions = [];
  private planNo: number;
  sponsorButtonDisabled: boolean;
  sponsorDocForm: UntypedFormGroup;
  userId: number;
  isNoDocSelected = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private planDetailsService: PlanDetailsService,
    private communicationService: CommunicationService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    if (sessionStorage.getItem('planNumber') != null) {
      this.planNumber = sessionStorage.getItem('planNumber');
    }
    this.planLabel = sessionStorage.getItem('planLabel');
    this.planId = sessionStorage.getItem('planId');
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : null;
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe((pending) => {
        this.isPending = pending;
      })
    );
    this.getPlanDetails();

    this.searchPrintSponsorDocument();
    this.sponsorDocForm = this.fb.group({
      planSponsorDoc: this.fb.array([]),
    });
  }
  getPlanDetails() {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(+this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
      })
    );
  }

  searchPrintSponsorDocument(): void {
    this.loading = true;
    this.sponsorButtonDisabled = true;
    this.subscriptions.push(
      this.planDetailsService.searchPrintSponsorDocument(this.planNumber).subscribe(
        (res) => {
          this.createDoc = true;
          this.searchPrintSponsorDocs = res.trackFormDetails;
          this.loading = false;
          this.sponsorButtonDisabled = false;
          if (this.searchPrintSponsorDocs == null) {
            this.infoMessage = { message: 'There are no documents to generate for this plan', details: [] };
          }
        },
        (error) => {
          this.createDoc = false;
          window.scroll(0, 0);
          this.loading = false;
          if (error) {
            this.errorMessage = { message: error, details: [] };
          } else {
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        }
      )
    );
  }

  onClickCancel() {
    this.router.navigate(['/plan-summary', this.planId]);
  }

  retrieveSelectedDoc(): void {
    const selectedDocRequest = {
      planId : +this.planId,
      trackFormIds: this.sponsorDocForm.get('planSponsorDoc').value,
    };
    if(this.sponsorDocForm.valid && this.sponsorDocForm.value.planSponsorDoc?.length > 0) {
      this.subscriptions.push(
        this.planDetailsService.downloadPlanDocument(this.userId,selectedDocRequest).subscribe(
          (res) => {
            
            const blob = new Blob([res], { type: 'application/zip' });
            const file = window.URL.createObjectURL(blob);
            window.open(file, '_blank');
            
            window.scroll(0, 0);
            this.loading = false;
            this.successMessage =  { message: '', details: [] };
          },
          (error) => {
            window.scroll(0, 0);
            this.loading = false;
            this.successMessage =  { message: '', details: [] };
            if (error) {
              this.errorMessage = { message: error, details: [] };            
            } else {
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          }
        )
      );
    }
    else {
      window.scroll(0, 0);
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
   
  }

  selectedDocument(event: any): void {
    const planSponsorDoc: UntypedFormArray = this.sponsorDocForm.get('planSponsorDoc') as UntypedFormArray;
    this.planDetailsService.checkboxFormChange(event, planSponsorDoc);
    if(planSponsorDoc.length > 0) {
      this.isNoDocSelected = false;
    }
    else 
    {
      this.isNoDocSelected = true;
    }
  }  
}
