<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2 padding-left">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10 padding-left">
      <h1 id="main-title" class="page-title ng-binding">
        <span
          *ngIf="
            (planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1
          "
          ><i class="nw-icon-check f-green"></i
        ></span>
        &ensp;{{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }}) &ensp;
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom alert-container--error" *ngIf="errorMessage?.message?.length > 0">
        <div class="col-sm-12 no-padding">
          <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"></nfrp-alert>
        </div>
      </div>
      <ng-container *ngIf="createDoc">
      <form [formGroup]="enrollDocForm">
        <div class="box compartment summary animation-fade-in padding-sides">
          
            <div *ngIf="enrollmentDoc; else loading">
              <div class="padding-bottom">
                <div class="section__header" role="button" [ngClass]="{'pending-status-bg':isPending}">
                  <h2 class="content-title"><b>Available Booklet Documents</b></h2>
                  <i id="ul-li-view-header-chevron"></i>
                </div>
                <div role="alert" aria-live="assertive" class="alert-container alert-container&#45;&#45;info group">
                  <span class="nw-icon-info-new"> </span>
                  <div class="message">
                    <div>
                      .&emsp; SELECT both items below to create the Participant Enrollment documents. BOTH are REQUIRED
                      for use with the Enrollment Booklet.
                    </div>
                  </div>
                </div>
                <div class="content form-group">
                  <div class="section__body">
                    <div *ngFor="let edp of enrollmentDoc; let i = index">
                      <label for="documentDetails">
                        <input type="checkbox" [value]="edp.documentId" (change)="onCheckboxChange($event, edp)" />
                        {{ edp.documentName }}
                        <span *ngIf="i === 0"
                          >- This document will be printed and inserted into the back of the Enrollment Kit.</span
                        >
                        <span *ngIf="i === 1"> - This document will be inserted into the Enrollment Booklet.</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
        <ng-container *ngIf="loading">
          <nfrp-spinner></nfrp-spinner>
        </ng-container>
        <div class="row" *ngIf="createDoc">
          <div class="col-sm-2">
            <input
              type="button"
              value="Create Docs"
              (click)="createDocument()"
              [disabled]="enrollDisabled"
              class="button button-primary"
            />
          </div>
          <div class="col-sm-9"></div>
          <div class="col-sm-1">
            <input
              type="button"
              type="submit"
              value="Cancel"
              (click)="onClickCancel()"
              class="button button-secondary"
            />
          </div>
        </div>
      </form>
    </ng-container>
    </div>
  </div>
</section>
