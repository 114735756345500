import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanDetailsService } from '../../services/plan-details.service';
import {
  GetPlanLabels,
  GetPlanNotes,
  GetPlanRepresentatives,
  GetPlans,
  GetPlanSolicitor,
  PlanSummaryResponse,
} from '../../model/get-plan.model';
import { NfrpDefaultGridComponent, NfrpGridService, NfrpAlertMessage } from 'nfrp-shared-angular';
import { FeatureTypeIDEnum, UserTypeIDEnum, MoneyManagerIDEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
import { FooterFeaturesService } from '../../services/footer-features.service';
@Component({
  selector: 'plan-summary',
  templateUrl: './plan-summary.component.html',
  styleUrls: ['./plan-summary.component.css'],
})
export class PlanSummaryComponent
  extends NfrpDefaultGridComponent<GetPlanRepresentatives>
  implements OnInit, OnDestroy
{
  planId: number;
  planDetails: GetPlans;
  planLabels: GetPlanLabels;
  planLabel: string;
  isViewPlanMode = true;
  planNotes: GetPlanNotes[];
  isRiaAdmin = false;
  planStatusEnum = 1;
  showEditCorePlanOption = false;
  numberOfItems: number;
  userEmail: string;
  showSolicitorInfo = false;
  solicitorObj: GetPlanSolicitor;
  trusteeData: any;
  showArchiveOption = false;
  planFeeSchedule = false;
  hideBrinkerSolRep = false;
  isArchivePlan = false;
  hidePlanRepSection = false;
  errorMessage: NfrpAlertMessage;
  successMessage: NfrpAlertMessage;
  showSolicitorOption = false;
  pendingPlanStatus = false;
  private subscriptions = [];
  private planNumber: string;
  showStatus = false;
  showTick = false;  
  changeState : boolean;
  clsInvestments = MoneyManagerIDEnum.CLS_INVESTMENTS;
  isStadionMM = false;
  stadionPlanOption : string;
  isCoreFundStrategy = false;
  subFooterTabShow: boolean;
  constructor(
    private route: ActivatedRoute,
    private planDetailsService: PlanDetailsService,
    private footerFeaturesService: FooterFeaturesService,
    private communicationService: CommunicationService,
    nfrpGridService: NfrpGridService<GetPlanRepresentatives>,
    private router: Router
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : ''; 
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.subscriptions.push(
        this.communicationService.getPendingStatus().subscribe(pendingPlan => {
          if(pendingPlan) {            
            this.changeState = pendingPlan;
          }
          else if(sessionStorage.getItem('isPending')) {
            this.changeState = JSON.parse(sessionStorage.getItem('isPending'));
          }
        })
      );
      this.getPlanDetails();
      this.getPlanSolicitor();
    });
  }

  getPlanDetails(): void {
    this.subscriptions.push(
    this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.changeState).subscribe(
      (data: GetPlans) => {
        window.scroll(0, 0);
        data = this.setPlanStatusForActivePendingPlans(data);
        this.planDetails = data.planOverviews;
        this.planNumber = data.planOverviews?.planCaseNumber;
        this.planLabel = data.planOverviews?.planLabel;
        this.changeState = data.planOverviews?.pending === false ? false : true;        
        this.communicationService.setPendingStatus(this.changeState);
        this.stadionMMData();
        this.pendingPlanStatus = data.planOverviews?.hasPendingChanges;
        sessionStorage.setItem('planNumber', this.planNumber);
        sessionStorage.setItem('planLabel', this.planLabel);
        sessionStorage.setItem('planId', `${this.planId}`);
        this.showGreenTick(this.planDetails);
        this.footerFeaturesService.setFeatureListForLeftNav(this.pendingPlanStatus, this.changeState);
        this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.VIEW_PLANS, this.userEmail).subscribe();
        if (this.planDetails &&
          (this.planDetails.userTypeID === UserTypeIDEnum.RIA ||
          this.planDetails.userTypeID === UserTypeIDEnum.TPA ||
          this.planDetails.userTypeID === UserTypeIDEnum.TPA_TEAM ||
          this.planDetails.userTypeID === UserTypeIDEnum.NB_TEAM)
          ) {
          this.showEditCorePlanOption = true;
        }
        if (this.planDetails?.userTypeID === UserTypeIDEnum.RIA) {
          this.isRiaAdmin = true;
        }
        if (this.planDetails && (this.planDetails.archivedDate != null || this.planDetails.planArchived === this.planStatusEnum)) {
          this.showArchiveOption = true;
        }
        this.hideBrinkerSolRep = (this.planDetails && 
          (this.planDetails.moneyManagerId === MoneyManagerIDEnum.CLS_INVESTMENTS && 
            (this.planDetails.coadvisoryId != null && this.planDetails.coadvisoryId > 0)));
        this.showSolicitorOption = this.planDetails?.userTypeID === UserTypeIDEnum.MONEY_MANAGER ? true : false;
        this.isArchivePlan = this.planDetails?.planArchived === BinaryResponseEnum.NO ? true : false;
        this.communicationService.changeArchivePlanStatus(this.isArchivePlan);
        this.showStatusBasedContent(this.planDetails);
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  setPlanStatusForActivePendingPlans(data: GetPlans) : GetPlans {
    if (data.planOverviews.hasPendingChanges && this.changeState) {
      data.planOverviews.status = 'Under Mgmt. (Trac)'
      data.planOverviews.isTrackActive = 1
    }
    return data;
  }

  getPlanSolicitor(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanSolicitor(this.planId, this.changeState).subscribe((data: GetPlanSolicitor) => {
        this.solicitorObj = data.approvedPlanSolicitorResponse;
        if (this.solicitorObj &&
          this.solicitorObj.appSolicitorName != null &&
          this.solicitorObj.appSolicitorId != null
        ) {
          this.showSolicitorInfo = true;
          if(this.solicitorObj.appSolicitorId === 0){
            this.hidePlanRepSection = true;
          }
          else if(this.solicitorObj.appSolicitorId !== 0) {
            this.hidePlanRepSection = false;
          }
        }      
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  updateArchivePlanStatus(): void {
    this.subscriptions.push(
      this.planDetailsService.updateArchivePlan(this.planId).subscribe(
        (data: PlanSummaryResponse) => {
          this.isArchivePlan = true;
          this.communicationService.changeArchivePlanStatus(true);
          this.errorMessage = { message: '', details: [] };
          this.successMessage =  { message: data.status, details: [] };
        },
        (error) => {
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message:error, details: [] };
      })
    );
  }

  updateUnarchivePlanStatus(): void {
    this.subscriptions.push(
      this.planDetailsService.updateUnarchivePlan(this.planId).subscribe(
        (data: PlanSummaryResponse) => {
          this.isArchivePlan = false;
          this.communicationService.changeArchivePlanStatus(false);
          this.errorMessage = { message: '', details: [] };
          this.successMessage =  { message: data.status, details: [] };
        },
        (error) => {
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message:error, details: [] };
        })
    );
  }

  showStatusBasedContent(plan: GetPlans): void {
    if (plan.hasPendingChanges && plan.pending) {
      this.showStatus = true;
    } else if (!plan.hasPendingChanges && !plan.pending) {
      this.showStatus = true;
    } else {
      this.showStatus = false;
    }
  }

  showGreenTick(planDetails: GetPlans): void {
    if ((planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.status === 'Under Mgmt. (Trac)'){
      this.showTick = true;
    }
    else {
      this.showTick = false;
    }
  }
  
  changeInState(): void {    
    sessionStorage.setItem('isPending', JSON.stringify(this.changeState));
    this.getPlanDetails();
    this.getPlanSolicitor();
    this.footerFeaturesService.getFeatureListForLeftNav().subscribe(status => {
      this.subFooterTabShow = status;
    });
  }

  stadionMMData(): void {
    this.isStadionMM = (this.planDetails.moneyManagerId === MoneyManagerIDEnum.STADION_MONEY_MGMT) ? true: false;
    this.isCoreFundStrategy = this.planDetails.stadionCoreFund ? true : false;
    this.stadionPlanOption = (this.planDetails.storyLineOption || this.planDetails.planAutoEnroll) == 1 ? 'Storyline with QDIA' : (this.planDetails.storyLineOption || this.planDetails.planAutoEnroll) == 0 ? 'Storyline with Participant Choice' :null;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  
}
