<section class="container-fluid temp-color" xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <div class="col-sm-2 padding-left">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10 padding-left">
      <h1 id="main-title" class="page-title ng-binding">
        <span
          *ngIf="
            (planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1
          "
          ><i class="nw-icon-check f-green"></i
        ></span>
        &ensp;{{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }}) &ensp;
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom alert-container--error" *ngIf="errorMessage?.message?.length > 0">
        <div class="col-sm-12 no-padding">
          <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"></nfrp-alert>
        </div>
      </div>
      
      <form  [formGroup]="printEnrollDocForm" (ngSubmit)="downloadSelectedDoc()">
        <ng-container *ngIf="createDoc"> 
          <div *ngIf="searchEnrollmentDocs; else loading">
            <div class="box compartment summary animation-fade-in">
              <div class="section__header" role="button" [ngClass]="{'pending-status-bg':isPending}">
                <h2 class="content-title"><b>Print Enrollment Documents</b></h2>
                <i id="ul-li-view-header-chevron"></i>
              </div>
              <div class="content">
                <div class="section__body">
                  <div role="alert" aria-live="assertive" class="alert-container alert-container&#45;&#45;info group">
                    <h3>Both PDF documents must be included in the enrollment booklet.</h3>
                    <ul>
                      <li>
                        <u>If Nationwide is placing the booklet order for you,</u>
                        <small
                          >no further action is necessary on your part. These Participant Enrollment documents will be
                          made available to the Nationwide Enrollment Book team through RIA Services to include in the
                          booklets. If you have not already completed and submitted the Nationwide Enrollment Book Request
                          Form, please forward that document to Nationwide at least 8 business days prior to the scheduled
                          enrollment meeting date.</small
                        >
                      </li>
                      <li>
                        <u>If You are editing an existing enrollment book in the system,</u> these documents are required.
                        You can save them to your hard drive and then upload them when prompted in the enrollment book
                        website. If the forms are already included in the existing book and there have been no changes
                        that impact the Participant Agreement, this step may not be neccessary. You must view your proof
                        of the enrollment book to verify the book and contents (including Managed Account Services
                        documents) are up to date before placing an order.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ul>
                <li *ngFor="let printDoc of searchEnrollmentDocs; let i = index" class="li-style-none">
                  <div class="padding-left">
                    <div class="col-sm-12">
                      <label for="printEnrollmentDoc"><input type="checkbox"  id="checkbox-label-1-id" [value]="printDoc.trackFormId" (change)="selectedDocument($event)"><b>&nbsp;{{ printDoc.documentLabel }}</b>
                      <BR />
                      <div class="padding-left-2">
                        {{ printDoc.createdDate }}&nbsp;{{ printDoc.createdTimestamp }} - {{ printDoc.trackFormId }}
                      </div>
                      <br /></label>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="col-sm-4">
            <input type="button" type="submit" value="Retrieve Selected Documents" [disabled]="!isValidDocSelected" class="button button-primary" />
          </div>
          <div class="col-sm-offset-6 col-sm-1">
            <input type="button" type="submit" value="Cancel" (click)="onClickCancel()" class="button button-secondary" />
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
