import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { feeLimit, alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, ActionTypeEnum, MoneyManagerIDEnum, BinaryResponseEnum, UserTypeIDEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
import { GetPlanFees, GetPlans, GetPlanSolicitor, TpaFees, PlanSummaryResponse, PendingPlanResponse } from '../../../model/get-plan.model';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { PlanDetailsService } from '../../../services/plan-details.service';

@Component({
  selector: 'lib-plan-fee-schedule',
  templateUrl: './plan-fee-schedule.component.html',
  styleUrls: ['./plan-fee-schedule.component.css'],
})
export class PlanFeeScheduleComponent implements OnInit, OnDestroy {
  planFees: GetPlanFees;
  selectedAction: string;
  planId: number;
  userEmail: string;
  planDetails: GetPlans;
  mnyMgrId: number;
  feeLimit = feeLimit;
  totalAdminFee = 0;
  totalAdvisoryFee = 0;
  planScheduleRequestObj: GetPlanFees;
  isPlanNWAdmin = 0;
  planDetailsObj: GetPlans;
  loading = false;
  planFeeScheduleForm: UntypedFormGroup;
  feeVerificationForm: UntypedFormGroup;
  planScheduleId: number;
  mnyMgrProgramCode: string;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  infoMessage: NfrpAlertMessage;
  isAdminstratior = false;
  feeVerification = false;
  formSubscription: Subscription;
  userTypeID: number;
  showZeroFeeLimit = false;
  showMgoFeeLimit = false;
  showCLSFeeLimit = false;
  riaMgoFeeLimit: number;
  solicitorObj: GetPlanSolicitor;
  showSolicitorFeeLimit = false;
  showTpaZeroFeeLimit = false;
  showSolicitorMMFeeLimit = false;
  showFLSZeroFeeLimit = false;
  loggedInUserId: number;
  isManagerFee = false;
  isRiaFee = false;
  isPlanRiaVerified = false;
  isPlanManagerVerified = false;
  private subscriptions = [];
  verificationFees: GetPlanFees;
  isSaveFeeSubmitted = false;
  isMnyRiaFeeVerified = false;
  savePlanSubmitted = false;
  tpAdminNbr: number;
  tpaAddDefaultFee: number;
  isPending: boolean;
  tpaLimit = [];
  tptLimit = [];
  mgrLimit = [];
  riaLimit = [];
  solLimit = [];
  hideRiaFee = false;
  hideSolFee = false;
  hideMgrFee = false;
  hideTptFee = false; 
  hideTpaFee = false; 
  coAdvisoryFirm: string;
  saveButton: string;
  showVerificationMsg = false;

  constructor(
    private fb: UntypedFormBuilder,
    private planDetailsService: PlanDetailsService,
    private route: ActivatedRoute,
    private navigationStore: NavigationStore,
    private router: Router,
    private footerFeaturesService: FooterFeaturesService,
    private communicationService: CommunicationService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    if (loginUser) {
      this.userTypeID = JSON.parse(loginUser)?.userTypeId;
      this.loggedInUserId = JSON.parse(loginUser)?.userId;
      this.userEmail = JSON.parse(loginUser)?.userEmail;
    }
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe(pending => {
        this.isPending = pending;
      })
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.selectedAction = params.action;
      this.getPlanDetails();
      this.getPlanSolicitor();
    });

    this.planFeeScheduleForm = this.fb.group({
      nrpBundled: [0],
      nrpTpa: [0],
      riaServices: [0],
      advisoryFeesSolicitor: [0],
      advisoryFeesManager: [0],
    });

    this.feeVerificationForm = this.fb.group({
      isMnyMgrFee: [null],
      isRiaServiceFee: [null],
    });

    this.formSubscription = this.planFeeScheduleForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });

    this.formSubscription = this.feeVerificationForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });

    if (this.selectedAction === ActionTypeEnum.EDIT) {
      this.saveButton = 'Edit Fee Schedule';
      this.getVerificationFees();
    } else {
      this.saveButton = 'Add Fee Schedule';
      this.onChangeAdminFees();
      this.onChangeAdvisoryFees();
    }
  
    this.planFeeScheduleForm.get('nrpTpa').valueChanges.subscribe((nrpTpa) => {
      const nrpTotal = Number(nrpTpa) + Number(this.planFeeScheduleForm.value.nrpBundled);
      if (Number(nrpTpa) > 0.15 && Number(this.planFeeScheduleForm.value.advisoryFeesSolicitor) > 0.2) {
        this.infoMessage = { message: alertMessages.PLAN_FEE_COMPENSATION_EXCEEDS, details: [] };
      } else {
        this.infoMessage = { message: '', details: [] };
      }
    });
    this.planFeeScheduleForm.get('nrpBundled').valueChanges.subscribe((nrpBundled) => {
      const nrpTotal = Number(this.planFeeScheduleForm.value.nrpTpa) + Number(nrpBundled);
    });
    this.planFeeScheduleForm.get('advisoryFeesSolicitor').valueChanges.subscribe((advisoryFeesSolicitor) => {
      if (Number(advisoryFeesSolicitor) > 0.2 && Number(this.planFeeScheduleForm.value.nrpTpa) > 0.15) {
        this.infoMessage = { message: alertMessages.PLAN_FEE_COMPENSATION_EXCEEDS, details: [] };
      } else {
        this.infoMessage = { message: '', details: [] };
      }
    });
    this.navigationStore.set('formChanged', false);
  }
 
  getPlanFees(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanFees(this.planId,this.loggedInUserId, this.isPending).subscribe((data: GetPlanFees) => {
        this.planFees = data;
        if (this.planFees) {
          this.planScheduleId = data.planFeeScheduleId;
          this.mnyMgrProgramCode = data.mnyMgrProgramCode;
          this.tpaLimit = this.setSelectDataWithLimits(this.planFees.tpaMin,this.planFees.tpaMax);
          this.tptLimit = this.setSelectDataWithLimits(this.planFees.tptMin,this.planFees.tptMax);
          this.riaLimit = this.setSelectDataWithLimits(this.planFees.riaMin,this.planFees.riaMax);
          this.solLimit = this.setSelectDataWithLimits(this.planFees.solMin,this.planFees.solMax);
          this.mgrLimit = this.setSelectDataWithLimits(this.planFees.mgrMin,this.planFees.mgrMax);
          this.setFormData(data);
          this.navigationStore.set('formChanged', false);
        }
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  getVerificationFees(): void {
    this.subscriptions.push(
      this.planDetailsService.getVerificationFees(this.planId, this.isPending).subscribe((data: GetPlanFees) => {
        this.verificationFees = data;
        if (this.verificationFees) {
          if((data.riaFeeFlag === 1 || data.mnyMgrFeeFlag === 1) && (this.userTypeID === UserTypeIDEnum.RIA)) {
            this.showVerificationMsg = true;
          } else {
            this.showVerificationMsg = false;
          }
          this.setVerificationFormData(data);
          this.navigationStore.set('formChanged', false);
        }
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  getPlanSolicitor(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanSolicitor(this.planId, this.isPending).subscribe((data: GetPlanSolicitor) => {
        this.solicitorObj = data.approvedPlanSolicitorResponse;
        this.navigationStore.set('formChanged', false);
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }
  setVerificationFormData(data: GetPlanFees): void {
    if (data) {
      this.feeVerificationForm.patchValue({
        isMnyMgrFee: data?.mnyMgrFeeFlag ? true : false,
        isRiaServiceFee: data?.riaFeeFlag ? true : false,
      });
      this.isRiaFee = data.riaFeeFlag === BinaryResponseEnum.NO ? true: false;
      this.isManagerFee = data.mnyMgrFeeFlag === BinaryResponseEnum.NO ? true : false;
      if ((this.isRiaFee || this.isManagerFee) && (this.userTypeID === UserTypeIDEnum.RIA)) {
        this.feeVerification = true;
      }
      else {
        this.feeVerification = false;
      }
      this.isPlanRiaVerified = true;
      this.isPlanManagerVerified = true;
    } else {
      this.isPlanRiaVerified = false;
      this.isPlanManagerVerified = false;
    }
  }
  planFeeChangeEvent(event: any, riaMMFee: string): void {
    if (riaMMFee === 'isRiaServiceFee') {
      this.isPlanRiaVerified = false;
    }
    if (riaMMFee === 'isMnyMgrFee') {
      this.isPlanManagerVerified = false;
    }
  }
  setFormData(data: GetPlanFees): void {
    if (data) {
      const planFees = data;
      this.planFeeScheduleForm.patchValue({
        nrpBundled: this.isAdminstratior ? this.toValue(planFees.planFeeTpa) : '',
        nrpTpa: this.isAdminstratior ? this.toValue(planFees.planFeeTpaTeam) : this.toValue(planFees.planFeeTpa),
        riaServices: this.toValue(planFees.planFeeRias),
        advisoryFeesSolicitor: this.toValue(planFees.planFeeSolicitor),
        advisoryFeesManager: this.toValue(planFees.planFeeManager),
      });      
    }
    this.navigationStore.set('formChanged', false);
    this.onChangeAdminFees();
    this.onChangeAdvisoryFees();
  }

  toValue(value) {
    const val = +value?.replace('%', '');
    return val;
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
        this.mnyMgrId = this.planDetails?.moneyManagerId;
        this.coAdvisoryFirm = this.planDetails.coAdvisoryFirm;
        this.tpAdminNbr = this.planDetails?.tpAdminNbr;
        this.isPlanNWAdmin = this.planDetails?.isPlanNwAdmin;
        if (this.planDetails?.isPlanNwAdmin === BinaryResponseEnum.YES) {
          this.isAdminstratior = true;
        }
        this.getPlanFees();
        this.userTypeFeeRestriction();
     
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }
  onChangeAdminFees() {
    const nrpBundled = +this.planFeeScheduleForm?.value.nrpBundled;
    const nrpTpa = +this.planFeeScheduleForm?.value.nrpTpa;
    const riaServices = +this.planFeeScheduleForm?.value.riaServices;
    this.totalAdminFee = +(nrpBundled + nrpTpa + riaServices).toFixed(2);
    this.enableFeeVerification();
  }

  onChangeAdvisoryFees() {
    const advisoryFeesSolicitor = +this.planFeeScheduleForm?.value.advisoryFeesSolicitor;
    const advisoryFeesManager = +this.planFeeScheduleForm?.value.advisoryFeesManager;
    this.totalAdvisoryFee = +(advisoryFeesSolicitor + advisoryFeesManager).toFixed(2);
    this.enableFeeVerification();
  }

  enableFeeVerification() {
    const riaServices = +this.planFeeScheduleForm?.value.riaServices;
    const advisoryFeesManager = +this.planFeeScheduleForm?.value.advisoryFeesManager;
  }

  onSubmitScheduleForm() {
    if (this.selectedAction.toLowerCase() === ActionTypeEnum.EDIT) {
      this.editFormSubmit();
    } else {
      this.addFormSubmit();
    }
  }

  addFormSubmit(): void {
    this.savePlanSubmitted = true;
    this.planScheduleRequestObj = {
      planFeeTpa: this.isAdminstratior ? this.planFeeScheduleForm.value.nrpBundled?.toString() : this.planFeeScheduleForm.value.nrpTpa?.toString(),
      planFeeTpaTeam:  this.isAdminstratior ? this.planFeeScheduleForm.value.nrpTpa?.toString() : '0.00',
      planFeeRias: this.planFeeScheduleForm.value.riaServices?.toString(),
      planFeeSolicitor: this.planFeeScheduleForm.value.advisoryFeesSolicitor?.toString(),
      planFeeManager: this.planFeeScheduleForm.value.advisoryFeesManager?.toString(),
      planFeeTotal: (this.totalAdminFee + this.totalAdvisoryFee)?.toString(),
      isPlanNwAdmin: this.isPlanNWAdmin,
      planFeeAdvisory: this.totalAdvisoryFee?.toString(),
      planFeeAdmin: this.totalAdminFee?.toString(),
      planId: +this.planId,
      lastUpdatedUserId: this.loggedInUserId,
      mnyMgrProgramCode: this.mnyMgrProgramCode ? this.mnyMgrProgramCode : '',
    };
    this.planDetailsService.savePlanFeeSchedule(this.planId, this.planScheduleRequestObj, this.mnyMgrId).subscribe(
      (planFeeResponse: PendingPlanResponse) => {
        this.loading = false;
        window.scroll(0, 0);        
        this.errorMessage = { message: '', details: [] };
        this.successMessage = { message: planFeeResponse.message, details: [] };
        this.isPending = planFeeResponse.newPendingPlanCreated;
        this.communicationService.setIsPendingForNewPendingPlan(planFeeResponse); 
        this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);       
      },
      (error) => {
        this.loading = false;
        window.scroll(0, 0);
        this.successMessage = { message: '', details: [] };
        this.errorMessage = { message: error, details: [] };
      }
    );
    this.navigationStore.set('formChanged', false);
  }

  editFormSubmit(): void {
    this.planScheduleRequestObj = {
      planFeeTpa: this.isAdminstratior ? this.planFeeScheduleForm.value.nrpBundled?.toString() : this.planFeeScheduleForm.value.nrpTpa?.toString(),
      planFeeTpaTeam:  this.isAdminstratior ? this.planFeeScheduleForm.value.nrpTpa?.toString() : '0.00',
      planFeeRias: this.planFeeScheduleForm.value.riaServices?.toString(),
      planFeeSolicitor: this.planFeeScheduleForm.value.advisoryFeesSolicitor?.toString(),
      planFeeManager: this.planFeeScheduleForm.value.advisoryFeesManager?.toString(),
      planFeeTotal: (this.totalAdminFee + this.totalAdvisoryFee)?.toString(),
      isPlanNwAdmin: this.isPlanNWAdmin,
      planFeeAdvisory: this.totalAdvisoryFee?.toString(),
      planFeeAdmin: this.totalAdminFee?.toString(),
      planId: +this.planId,
      planFeeScheduleId: this.planScheduleId ? this.planScheduleId : 0,
      mnyMgrProgramCode: this.mnyMgrProgramCode ? this.mnyMgrProgramCode : '',
      lastUpdatedUserId: this.loggedInUserId,
      pending: this.isPending,
      mnyMgrId: this.mnyMgrId
    };
    this.isSaveFeeSubmitted = true;
    this.planDetailsService
      .updatePlanFeeSchedule(this.planId, this.planScheduleRequestObj)
      .subscribe(
        (planFeeResponse: PendingPlanResponse) => {
          this.loading = false;
          this.errorMessage = { message: '', details: [] };
          this.isPending = planFeeResponse.newPendingPlanCreated;
          this.communicationService.setIsPendingForNewPendingPlan(planFeeResponse);
          this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
          this.navigationStore.set('formChanged', false);
          this.getVerificationFees();
          this.successMessage = { message: planFeeResponse.message, details: [] };
        },
        (error) => {
          this.loading = false;
          this.navigationStore.set('formChanged', false);
          this.errorMessage = { message: error, details: [] };
        }
      );
  }

  onSubmitVerificationForm() {
    if (!this.feeVerificationForm.value.isMnyMgrFee && !this.feeVerificationForm.value.isRiaServiceFee) {
      this.successMessage = { message: '', details: [] };
      this.errorMessage = { message: alertMessages.SELECT_PLAN_VERIFICATION_FEE, details: [] };
    } else {
      const feeVerificationObj = {
        isMnyMgrFee: this.feeVerificationForm.value.isMnyMgrFee ? BinaryResponseEnum.YES : BinaryResponseEnum.NO,
        isRiaServiceFee: this.feeVerificationForm.value.isRiaServiceFee
          ? BinaryResponseEnum.YES
          : BinaryResponseEnum.NO,
        lastUpdatedUserId: this.loggedInUserId,
        isPending: this.isPending
      };
      this.planDetailsService.savePlanFeeFlag(this.planId, feeVerificationObj).subscribe(
        (planFeeResponse) => {
          this.isPlanRiaVerified = true;
          this.isPlanManagerVerified = true;
          this.loading = false;
          this.errorMessage = { message: '', details: [] };
          this.router.navigate(['/plan-summary', this.planId]);
          this.successMessage = { message: planFeeResponse, details: [] };
        },
        (error) => {
          this.loading = false;
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: error, details: [] };
        }
      );
    }

    this.navigationStore.set('formChanged', false);
  }

  onCancel() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.router.navigate(['/plan-summary', this.planId]);
  }

  userTypeFeeRestriction(): void {
    const brinkerWithCoAdvisory = this.mnyMgrId === MoneyManagerIDEnum.CLS_INVESTMENTS && this.coAdvisoryFirm !== '' ? true : false;
    const brinkerWithOutCoAdvisory = this.mnyMgrId === MoneyManagerIDEnum.CLS_INVESTMENTS && this.coAdvisoryFirm === null ? true : false;
    const stadionMm = this.mnyMgrId === MoneyManagerIDEnum.STADION_MONEY_MGMT ? true : false;
    const flexibleInvestMmId = this.mnyMgrId === MoneyManagerIDEnum.FLEXIBLE_INVESTMENT ? true : false;
    const mgoMMid = this.mnyMgrId === MoneyManagerIDEnum.MGO_INVESTMENT ? true : false;
    const meederMMId = this.mnyMgrId === MoneyManagerIDEnum.MEEDER_ADVISORY_SERVICES ? true : false;
    const protfolioStgMMId = this.mnyMgrId === MoneyManagerIDEnum.PORTFOLIO_STRATEGIES ? true : false;
    const hanlonMMId = this.mnyMgrId === MoneyManagerIDEnum.HANLON ? true : false;
    const nWInvestmentAdvWithSol = this.mnyMgrId === MoneyManagerIDEnum.NW_INVESTMENT_ADVISOR_LLC && this.solicitorObj?.appSolicitorId ? true : false;
    const nWInvestmentAdvWithOutSol = this.mnyMgrId === MoneyManagerIDEnum.NW_INVESTMENT_ADVISOR_LLC && this.solicitorObj?.appSolicitorId === BinaryResponseEnum.NO ? true : false;
    switch (this.userTypeID) {
      case UserTypeIDEnum.RIA:
        break;
      case UserTypeIDEnum.CALL_CENTER:
      case UserTypeIDEnum.ENROLLMENT_KIT_PROCESSOR:
      case UserTypeIDEnum.MONEY_MANAGER:
        this.hideRiaFee = true;
        this.hideMgrFee = true;
        this.hideSolFee = true;
        this.hideTptFee = true;
        this.hideTpaFee = true;
        break;
      case UserTypeIDEnum.TPA_TEAM:        
      case UserTypeIDEnum.TPA:
        this.hideRiaFee = true;
        this.hideMgrFee = true;
        if(!stadionMm) {
          this.hideSolFee = true;
        }
        this.hideTptFee = true;
        this.hideTpaFee = true;
        break;
      case UserTypeIDEnum.NB_TEAM:
          this.hideRiaFee = true;
          this.hideMgrFee = true;
          this.hideSolFee = true;
          this.hideTptFee = true;
          this.hideTpaFee = true;
        break; 
    }
  }

  setSelectDataWithLimits(min:string, max: string): any[] {   
    const selectedArray = []; 
    this.feeLimit.forEach(limit => {
      if(limit >= parseFloat(min)   && limit <= parseFloat(max)) {
        selectedArray.push(limit);
      }
    })
    return selectedArray;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
