import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ErrorUtilsService } from './error-utils.service';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class KitProcessingService {
  private headers: HttpHeaders;
  baseUrl = this.config.config?.masRetailPrivateProcessing;
  constructor(private http: HttpClient, 
    private errorUtilsService: ErrorUtilsService,
    private config: ConfigService) {
    this.headers = new HttpHeaders({
      accessControlAllowOrigin: '*',
      print2Image: 'true',
      proxyAlternateURI: 'http://localhost:8080/',
    });
  }
  createKit(): Observable<any> {
    return this.http
      .get(this.baseUrl + `/status/create-kits`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  kitStatus(): Observable<any> {
    return this.http
      .get(this.baseUrl + `/status/kits-status`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  zipStatus(): Observable<any> {
    return this.http
      .get(this.baseUrl + `/status/zip-status`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  flagEnrollment(planIds: string): Observable<any> {
    const params =  `?plan-ids=${planIds}`;
    return this.http
      .post(this.baseUrl + `/status/flag-kits${params}`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  cleanUpKits(planIds: string): Observable<any> {
    const params =  `?plan-ids=${planIds}`;
    return this.http
      .post(this.baseUrl + `/status/cleanup-zips${params}`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

}
