import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AdvancedSearch, AdvancedSearchData } from '../model/advanced-search';
import { Router } from '@angular/router';
import { PlanSearch } from 'src/app/models/plan-search';
import { ErrorUtilsService } from 'src/app/services/error-utils.service';
import { AssignedRoles } from '../model/assigned-roles';
import { UserInfo, UserResponse } from '../model/edit-user';
import { MoneyManagerMgmt } from '../model/mny-mgr-mgmt';
import { MoneyManager, MoneyManagerData } from '../model/money-manager-data';
import { SearchUser } from '../model/search-user';
import { TpAdminData } from '../model/tpadmin-data';
import { SearchUsersStore } from '../search-users.store';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class SearchUsersService {
  searchUserResult$: Observable<SearchUser[]> = this.searchUsersStore.select('searchUserResult');
  assignedRoleResult$: Observable<AssignedRoles> = this.searchUsersStore.select('assignedRoleResult');
  assignedRoleByUserIdResult$: Observable<AssignedRoles> = this.searchUsersStore.select('assignedRoleByUserIdResult');
  moneyMgrResult$: Observable<MoneyManager[]> = this.searchUsersStore.select('moneyMgrResult');
  tpAdminResult$: Observable<TpAdminData[]> = this.searchUsersStore.select('tpAdminResult');
  tpAdminActiveResult$: Observable<TpAdminData[]> = this.searchUsersStore.select('tpAdminActiveResult');
  planSearchResult$: Observable<PlanSearch> = this.searchUsersStore.select('planSearchResult');
  private headers: HttpHeaders;
  private router: Router;
  baseUrl = this.config.config?.masRetailPrivateProcessing;
  constructor(
    private http: HttpClient,
    private searchUsersStore: SearchUsersStore,
    private errorUtilsService: ErrorUtilsService,
    private config: ConfigService
  ) {
    this.headers = new HttpHeaders({
      accessControlAllowOrigin: '*',
      print2Image: 'true',
      proxyAlternateURI: 'http://localhost:8080/',
    });
  }
  /** HTTP GET to load user search information from the database table */
  getUserDetails(userId: number, searchQuery?: string): Observable<SearchUser> {
    const params = new HttpParams()
      .set('search-term', searchQuery ? searchQuery : '')
    return this.http.get<SearchUser>(this.baseUrl + `/search-user/${userId}`, { params }).pipe(
      tap((searchList: SearchUser) => {
        this.searchUsersStore.set('searchUserResult', searchList);
      }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  /** HTTP GET to load plan   search information from the database table */
  getPlanSearchDetails(
    searchQuery: string,
    userId: number,
    pendingStatus?: boolean,
    pageSize?: number,
    pageNo?: number
  ): Observable<PlanSearch> {
    const params = new HttpParams()
      .set('search-str', searchQuery ? searchQuery : '')
      .set('user-id', userId ? userId.toString() : '0')
      .set('is-pending', pendingStatus ? pendingStatus : false)
      .set('page-size', pageSize ? pageSize : 10)
      .set('page-no', pageNo ? pageNo : 0);
    return this.http.get<PlanSearch>(this.baseUrl + `/plan/plan-search`, { params }).pipe(
      tap((searchList: PlanSearch) => {
        this.searchUsersStore.set('planSearchResult', searchList);
      }),
      catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  /** HTTP GET to load assigned roles from the database table for list of radiobutton in edit user functionality */
  getAssignedRoles(): Observable<AssignedRoles> {
    const serviceURL = this.baseUrl + '/get-all-roles';
    return this.http.get<AssignedRoles>(serviceURL).pipe(
      tap((searchList: AssignedRoles) => {
        this.searchUsersStore.set('assignedRoleResult', searchList.roles);
      })
    );
  }

  /** HTTP GET to load assigned roles from the database table for list of radiobutton in edit user functionality */
  getAssignedRoleByUserId(userId: string): Observable<AssignedRoles> {
    const params = new HttpParams().set('id', userId ? userId : '');
    const serviceURL = this.baseUrl + '/get-user-type-roles';
    return this.http.get<AssignedRoles>(serviceURL, { params }).pipe(
      tap((searchList: AssignedRoles) => {
        this.searchUsersStore.set('assignedRoleByUserIdResult', searchList.roles);
      })
    );
  }
  /** HTTP GET to load Money manager from the database table for dropdown functionality */
  getMoneyManagerMaintenance(): Observable<MoneyManager[]> {
    return this.http.get<MoneyManager[]>(this.baseUrl + `/manager/main`).pipe(
      tap((searchList: MoneyManager[]) => {
        this.searchUsersStore.set('moneyMgrResult', searchList);
      }),
      catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  /** HTTP GET to load Money manager from the database table for dropdown functionality */
  getMoneyManagerForUser(emailId?: string): Observable<MoneyManager[]> {
    const params = this.setEmailParams(emailId);
    return this.http.get<MoneyManager[]>(this.baseUrl + `/manager/user-mgmt`, { params }).pipe(
      tap((searchList: MoneyManager[]) => {
        this.searchUsersStore.set('moneyMgrResult', searchList);
      }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
  /** HTTP GET to load Money manager with active and walled off from the database table for dropdown functionality */
  getMoneyManagerActWalledOff(mnyMgrAct?: number, mnyMgrWF?: number): Observable<MoneyManagerMgmt[]> {
    const serviceURL = this.baseUrl + `/get-mny-mgrs/${mnyMgrAct}/${mnyMgrWF}`;
    return this.http.get<MoneyManagerMgmt[]>(serviceURL).pipe(
      tap((searchList: MoneyManagerMgmt[]) => {
        this.searchUsersStore.set('moneyManagerActWalledOffResult', searchList);
      })
    );
  }
  /** HTTP GET to load TPA Admin from the database table for dropdown functionality */
  getTpAdmin(tpaNo?: string, userId?: number): Observable<TpAdminData[]> {
    let params = new HttpParams();
    if(userId){
      params = params.set('user-id', userId.toString());
    }
    else if(tpaNo){
      params = params.set('tpa-no', tpaNo);
    }
    return this.http.get<TpAdminData[]>(this.baseUrl + `/get-tpadmin`, { params }).pipe(
      tap((searchList: TpAdminData[]) => {
        this.searchUsersStore.set('tpAdminResult', searchList);
      }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
  /** HTTP GET to load TPA Admin from the database table for dropdown functionality */
  getTpADetails(tpaNo?: string): Observable<TpAdminData> {
    const params = new HttpParams().set('tpadmin-nbr', tpaNo ? tpaNo : '');
    const serviceURL = this.baseUrl + '/get-tpa';
    return this.http.get<TpAdminData>(serviceURL, { params }).pipe(
      tap((searchList: TpAdminData) => {
        this.searchUsersStore.set('tpAdminResult', searchList);
      })
    );
  }

  /** HTTP GET to load TPA Admin from the database table for dropdown functionality */
  getTpAdminActive(tpaAdminActive?: number): Observable<TpAdminData[]> {
    const serviceURL = this.baseUrl + `/get-tpadmin/${tpaAdminActive}`;
    return this.http.get<TpAdminData[]>(serviceURL).pipe(
      tap((searchList: TpAdminData[]) => {
        this.searchUsersStore.set('tpAdminActiveResult', searchList);
      })
    );
  }
  /** HTTP POST  to save the edited user information to the database table */
  saveEditUserData(editUserData: UserInfo): Observable<UserResponse> {
    return this.http
      .post(this.baseUrl + `/edit-user`, editUserData)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
  /** HTTP POST  to save the user information to the database table */
  saveAddUserData(addUserData: UserInfo): Observable<UserResponse> {
    return this.http
    .post(this.baseUrl + `/create-user`, addUserData)
    .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
  /** HTTP POST  to save the money manager information to the database table */
  saveAddMoneyManagerData(moneyMgrInfo: MoneyManagerData): Observable<UserResponse> {
    return this.http
      .post(this.baseUrl + `/create-mny-mgr`, moneyMgrInfo)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
  /** HTTP POST  to save the edited money manager information to the database table */
  saveEditMoneyManagerData(moneyMgrInfo: MoneyManager): Observable<UserResponse> {
    return this.http
      .put(this.baseUrl + '/manager/update', moneyMgrInfo)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
  /** HTTP POST  to save the tpadmin information to the database table */
  saveAddTpAdminData(tpAdminInfo: TpAdminData): Observable<UserResponse> {
    return this.http
    .post(this.baseUrl + '/create-tpa', tpAdminInfo)
    .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
  /** HTTP POST  to save the edited tpadmin information to the database table */
  saveEditTpAdminData(tpAdminInfo: TpAdminData): Observable<UserResponse> {
    return this.http
    .post(this.baseUrl + '/edit-tpa', tpAdminInfo)
    .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  advanceSearch(advanceSearch: AdvancedSearch): Observable<AdvancedSearchData> {
    return this.http.post(this.baseUrl + '/plan/advance-search', advanceSearch).pipe(
      catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

   /** HTTP GET to load Money manager for plans from the database table for dropdown functionality */
   getMoneyManagerForPlans(emailId: string, planId?: number): Observable<MoneyManager[]> {
    const params = new HttpParams().set('user-email', emailId ? emailId : '').set('plan-id', planId ? planId : 0 );
    return this.http.get<MoneyManager[]>(this.baseUrl + '/manager/plan', { params }).pipe(
      tap((searchList: MoneyManager[]) => {
        this.searchUsersStore.set('moneyMgrResult', searchList);
      }),
      catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  /** HTTP GET to load Money manager for advanced search from the database table for dropdown functionality */
  getMoneyManagerAdvancedSearch(emailId: string): Observable<MoneyManager[]> {
    const params = this.setEmailParams(emailId);
    return this.http.get<MoneyManager[]>(this.baseUrl + `/manager/advanced`, { params }).pipe(
      tap((searchList: MoneyManager[]) => {
        this.searchUsersStore.set('moneyMgrResult', searchList);
      }),
      catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

   /** HTTP GET a Money manager from the database table for edit functionality */
  getMoneyManagerWithId(mmId: string): Observable<MoneyManager> {
    const params = new HttpParams().set('mny-mgr-id', mmId ? mmId : '');
    return this.http.get<MoneyManager>(this.baseUrl + `/manager/details`, { params }).pipe(
      tap((searchList: MoneyManager) => {
        this.searchUsersStore.set('moneyMgrResult', searchList);
      }),
      catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  setEmailParams (id: string) : HttpParams{
    const params = new HttpParams().set('user-email', id ? id : '');
    return params;
  }

   /** HTTP GET to load TPA Admin with No NW admin from the database table for dropdown functionality */
  getTpadminWithNoNwAdmin(): Observable<TpAdminData[]> {
    const params = new HttpParams().set('nw-admin', 0);
    return this.http.get<TpAdminData[]>(this.baseUrl + `/get-tpadmin`, { params }).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  getUserByUserType(userTypeId:number,subSelection?:string): Observable<any[]> {
    let params = new HttpParams().set('user-type-id', userTypeId);
    if(subSelection) {
      params = new HttpParams().set('user-type-id', userTypeId)
      .set('sub-sel',subSelection);
    }    
    return this.http.get<any[]>(this.baseUrl + `/get-users-by-type`, { params }).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  getTpaBySearch(userId:number,searchQuery?:string): Observable<any[]> {
    const params = new HttpParams()
      .set('search-term', searchQuery)
      .set('user-id', userId);
    return this.http.get<any[]>(this.baseUrl + `/search-tpa`, { params }).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
}
