<section class="container-fluid temp-color" xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <div class="col-sm-2 padding-left">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10 padding-left">
      <h1 id="main-title" class="page-title ng-binding">
        <span
          *ngIf="
            (planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1
          "
          ><i class="nw-icon-check f-green"></i
        ></span>
        &ensp;{{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }}) &ensp;
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <form  [formGroup]="sponsorDocForm" (ngSubmit)="retrieveSelectedDoc()">
        <div class="margin-bottom alert-container--error" *ngIf="errorMessage?.message?.length > 0">
          <div class="col-sm-12 no-padding">
            <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"></nfrp-alert>
          </div>
        </div>
        <div class="margin-bottom" *ngIf="infoMessage?.message?.length > 0">
          <div class="col-sm-12 no-padding">
            <nfrp-alert alertId="info-ekp" [message]="infoMessage" type="info" [page]="false"></nfrp-alert>
          </div>
        </div>
        <div class="box compartment summary animation-fade-in">
          <ng-container *ngIf="createDoc"> 
            <div class="section__header" role="button" [ngClass]="{'pending-status-bg':isPending}">
              <h2 class="content-title"><b>Print Plan Documents</b></h2>
              <i id="ul-li-view-header-chevron"></i>
            </div>
            <div class="content">
              <div class="section__body">
                <ng-container *ngIf="createDoc">
                  <div *ngIf="searchPrintSponsorDocs; else loading">
                    <div *ngFor="let psd of searchPrintSponsorDocs; let i = index">
                      <div class="padding-left">
                        <div class="col-sm-12">
                          <label for="planSponsorDoc"><input type="checkbox"  id="checkbox-label-1-id" [value]="psd.trackFormId" (change)="selectedDocument($event)"><b>&nbsp;{{ psd.documentLabel }}</b>
                          <BR />
                          <div class="padding-left-2">
                            {{ psd.createdDate }}&nbsp;{{ psd.createdTimestamp }} - {{ psd.trackFormId }}
                          </div>
                          <br /></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-template #loading>
            <nfrp-spinner></nfrp-spinner>
          </ng-template>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <input type="button" type="submit" value="Retrieve Selected Documents" [disabled]="sponsorButtonDisabled || isNoDocSelected" class="button button-primary" />
          </div>
          <div class="col-sm-7 padding-top"><b>(The forms you selected will be downloaded as a zip file) </b></div>
          <div class="col-sm-1">
            <input
              type="button"
              type="submit"
              value="Cancel"
              (click)="onClickCancel()"
              class="button button-secondary"
            />
          </div>
        </div> 
        <BR />
        <div>
          *In order to view and print PDF files on this site, you will need a PDF Reader.
        </div>
      </form>
    </div>
  </div>
</section>
