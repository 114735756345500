<section class="container-fluid temp-color">
  <br>
  <div class="row">
    <div class="col-sm-12 padding-left">
      <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-core-success" [message]="successMessage" type="success" [page]="false"> </nfrp-alert>
      </div>     
      <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-core-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
    </div> 
    <ng-container>            
      <div class="col-sm-12 padding-left-2 padding-bottom">
        <div class="section-header border-bottom">
          <h2 class="content-title no-margin-bottom">Kits Details</h2>
        </div>
        <div class="content">
          <div class="row">
            <p id="user-info-user-email" class="col-sm-3 no-margin-bottom">Total Records in Database:</p>
            <p id="user-info-user-email-data" class="col-sm-7 no-margin-bottom">
              {{processingKitData?.totalKits}}
            </p>
          </div>
          <div class="row">
            <p id="user-info-first-name" class="col-sm-3 no-margin-bottom">Kits Marked Completed:</p>
            <p id="user-info-first-name-data" class="col-sm-7 no-margin-bottom">
              {{processingKitData?.kitsCompleted}}
            </p>           
          </div>
          <div class="row">
            <p id="user-info-last-name" class="col-sm-3 no-margin-bottom">Kits Remaining:</p>
            <p id="user-info-last-name-data" class="col-sm-7 no-margin-bottom"> {{processingKitData?.kitsRemaining}}
            </p>              
          </div>
          <div class="row">
            <p id="nwid-nwid" class="col-sm-3 no-margin-bottom">Kits with errors:</p>
            <p id="nwid-data" class="col-sm-7 no-margin-bottom"> {{processingKitData?.kitErrors}}
            </p>
          </div>
        </div>
      </div>  
      <div class="col-sm-12 padding-left-2">
        <div class="section-header border-bottom">
          <h2 class="content-title no-margin-bottom">Kits Details</h2>
        </div>
        <div class="content">
          <div class="row">
            <p id="user-info-user-email" class="col-sm-3 no-margin-bottom">Total Kits in Directory:</p>
            <p id="user-info-user-email-data" class="col-sm-7 no-margin-bottom">
              {{kitData?.numFilesInZipDirectory}}
            </p>
          </div>
          <div class="row">
            <p id="user-info-first-name" class="col-sm-3 no-margin-bottom">Records marked Completed, missing Zip:</p>
            <p id="user-info-first-name-data" class="col-sm-7 no-margin-bottom">
              {{kitData?.kitsMissingZipFile}}
            </p>           
          </div>
          <div class="row">
            <p id="user-info-first-name" class="col-sm-3 no-margin-bottom">Kits missing Zip file in directory: <b>{{kitData?.plansMissingZipFile}}</b></p>                        
          </div>
          <div class="row">
            <p id="user-info-first-name" class="col-sm-3 no-margin-bottom">Zip files in directory but not in database: {{kitData?.numFilesMissingDbRecords ? kitData?.numFilesMissingDbRecords : 0}}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 padding-left-2">
        <div class="padding-bottom padding-top">
          <div class="flex-column">
            <label>Enter plan IDs to flag for processing.</label>
            <div class="padding-top">
              <textarea
                name="planIdForEnrollment"
                [(ngModel)]="planIdForEnrollment"
                id="planid_enrollment"
                rows="5"
                maxlength="1000"
                cols="90"
                (input)="validateValue($event)"           
              ></textarea>
            </div>         
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 padding-bottom">
            <input
            type="button"
            class="button button-primary hover-left margin-left"
            (click)="submitKits()"
            value="Submit Kits"
          />
          </div>   
        </div>
        <div class="padding-bottom">
          <div class="flex-column">
            <label>Enter plan IDs to clean up zip files.</label>
            <div class="padding-top">
              <textarea
                name="planIDCleanUp"
                [(ngModel)]="planIDCleanUp"
                id="planid_cleanup"
                rows="5"
                maxlength="1000"
                cols="90"  
                (input)="validateValue($event)"
              ></textarea>
            </div>         
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 padding-bottom">
            <input
            type="button"
            class="button button-primary hover-left margin-left"
            (click)="cleanUpKits()"
            value="Clean up Kits"
          />
          </div>   
        </div>
      </div>
    </ng-container>  
  </div>
</section>