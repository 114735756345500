import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { alertMessages, statesList, TpaAdminFee, TpaMMLimit, validationPatterns } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { UserResponse } from '../../../model/edit-user';
import { MoneyManager, MoneyManagerData } from '../../../model/money-manager-data';
import { TpAdminData } from '../../../model/tpadmin-data';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { SearchUsersService } from '../../../services/search-users.service';

@Component({
  selector: 'add-tpa',
  templateUrl: './add-tpa.component.html',
  styleUrls: ['./add-tpa.component.css'],
})
export class AddTpaComponent implements OnInit {
  tpAdminMode: string;
  tpAdminStates = statesList;
  tpaLimit = TpaMMLimit;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  submitted: boolean;
  tpAdminRequestObj: TpAdminData;
  isToggleAdminNw: boolean;
  tpaReqMnyMgrFee = [];
  tpaAdminFeeList = TpaAdminFee;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  tpAdminForm: UntypedFormGroup;
  userEmail: string;
  formSubscription: Subscription;
  private subscriptions = [];
  moneyManagerList: MoneyManager[];
  tpaMnyMgrDefaultFees = [];
  mnyMgrList$ = this.searchUsersService.moneyMgrResult$;
  constructor(
    private searchUsersService: SearchUsersService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private footerFeaturesService: FooterFeaturesService,
    private navigationStore: NavigationStore
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.TPA_MGMT, this.userEmail).subscribe()
    );
    this.getMoneyManager();
    this.tpAdminForm = this.fb.group({
      tpaNumber: [
        null,
        [Validators.required, Validators.maxLength(3), Validators.pattern(validationPatterns.alphaNumericPattern)],
      ],
      tpaName: [null, [Validators.required, Validators.maxLength(255)]],
      tpaAddress1: [null, [Validators.maxLength(100)]],
      tpaAddress2: [null, [Validators.maxLength(100)]],
      tpaCity: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.alphaNumericPattern)]],
      tpaState: [''],
      tpaZip: [null, [Validators.maxLength(10)]],
      tpaActive: [true],
      tpAdminNW: [false],
      tpAdminFeePrct: [0],
      tpaPhone: [null, [Validators.maxLength(30)]],
      tpaFax: [null, [Validators.maxLength(30)]],
      tpaEmail: [null, [Validators.maxLength(255), Validators.pattern(validationPatterns.emailPattern)]],
      tpaContact: [null, [Validators.maxLength(255)]],
      mnyMgrFeePct: [0.15],
    });
    this.formSubscription = this.tpAdminForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }
  get tpAdminControl(): { [key: string]: AbstractControl } {
    return this.tpAdminForm.controls;
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.tpAdminForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }
  selectedMMFees($event: any, mnyMgr: number): void {
    const tpaMnyMgrFee = {
      mnyMgrId: mnyMgr,
      defaultFee: +$event.target.value,
    };
    this.tpaMnyMgrDefaultFees.push(tpaMnyMgrFee);
  }
  cancel() {
    this.router.navigate(['/tpaMgmt']);
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
  }

  toggleFeeField(adminNw): void {
    if (adminNw === BinaryResponseEnum.YES) {
      this.isToggleAdminNw = true;
    } else {
      this.isToggleAdminNw = false;
      this.tpAdminForm.controls.tpAdminFeePrct.setValue(0);
    }
  }

  getMoneyManager(): void {
    this.subscriptions.push(
      this.searchUsersService.getMoneyManagerMaintenance().subscribe((firmData: MoneyManager[]) => {
        this.moneyManagerList = firmData;
        this.moneyManagerList = this.moneyManagerList?.filter(
          (mnyMgr) => mnyMgr.wallOff === BinaryResponseEnum.NO && mnyMgr.active === BinaryResponseEnum.YES
        );
      },
      (error) => {
        this.errorMessage = { message: error, details : [] };
      })
    );
  }

  onSubmit(): void {
    this.submitted = true;
    this.resetAlertMessages();
    if (this.tpAdminForm.valid) {
      const tpAdminRequestObj = {
        tpAdminNbr: this.tpAdminForm.value.tpaNumber,
        tpAdminLabel: this.tpAdminForm.value.tpaName,
        tpAdminAddrA: this.tpAdminForm.value.tpaAddress1,
        tpAdminAddrB: this.tpAdminForm.value.tpaAddress2,
        tpAdminCity: this.tpAdminForm.value.tpaCity,
        tpAdminState: this.tpAdminForm.value.tpaState,
        tpAdminZip: this.tpAdminForm.value.tpaZip,
        tpAdminPhone: this.tpAdminForm.value.tpaPhone,
        tpAdminFax: this.tpAdminForm.value.tpaFax,
        tpAdminEmail: this.tpAdminForm.value.tpaEmail,
        tpAdminContact: this.tpAdminForm.value.tpaContact,
        tpAdminActive: this.tpAdminForm.value.tpaActive ? BinaryResponseEnum.YES : BinaryResponseEnum.NO,
        tpAdminNWAdmin: this.tpAdminForm.value.tpAdminNW ? BinaryResponseEnum.YES : BinaryResponseEnum.NO,
        tpAdminFeePercent: this.tpAdminForm.value.tpAdminFeePrct
          ? this.tpAdminForm.value.tpAdminFeePrct
          : BinaryResponseEnum.NO,        
        tpaMnyMgrFees:!this.isToggleAdminNw ? this.getMnyMgrTpaFees():[],
      };

      this.searchUsersService.saveAddTpAdminData(tpAdminRequestObj).subscribe(
        (tpAdminResponse: UserResponse) => {
          window.scroll(0, 0);
          this.errorMessage = { message: '', details: [] };
          this.successMessage = { message: tpAdminResponse.status, details: [] };
        },
        (error) => {
          window.scroll(0, 0);
          if (error) {
            this.errorMessage = { message: error, details: [] };
          } else {
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        }
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }

  resetAlertMessages() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
  }
  getMnyMgrTpaFees() {
    const mnyMgrFilterArray = [];
    const responseArray = [];
    if (!this.tpAdminForm.value.tpAdminNW) {
      this.moneyManagerList.forEach((obj) => {
        const existMM = this.tpaMnyMgrDefaultFees.find((fee) => obj.moneyManagerId === fee.mnyMgrId);
        if (!existMM) {
          mnyMgrFilterArray.push({ mnyMgrId: obj.moneyManagerId, defaultFee: 0.15 });
        }
      });
      responseArray.push(...mnyMgrFilterArray, ...this.tpaMnyMgrDefaultFees);
    } else {
      this.moneyManagerList.forEach((obj) => {
        responseArray.push({ mnyMgrId: obj.moneyManagerId, defaultFee: 0.0 });
      });
    }
    return responseArray;
  }
}
