<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2 padding-left">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10 padding-left">
      <h1 *ngIf="!isAddMode" id="main-title" class="page-title ng-binding">
        <span
          *ngIf="
            (planDetailsData?.planIsTMSActive === 1 || planDetailsData?.isTrackActive === 1) &&
            planDetailsData?.planStatus === 1
          "
          ><i class="nw-icon-check f-green"></i
        ></span>
        &ensp;{{ planDetailsData?.planLabel }} - ({{ planDetailsData?.planCaseNumber }}) &ensp;
        <span *ngIf="planDetailsData?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="no-padding-top">
        <nfrp-alert
          *ngIf="editPlanInfo?.message?.length > 0"
          alertId="editplan-display-message"
          [message]="editPlanInfo"
          type="info"
          [page]="false"
        >
        </nfrp-alert>
      </div>
      <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-core-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-core-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container>
        <form class="mt-10" [formGroup]="corePlanForm" (ngSubmit)="onSubmit()">
          <ul id="core-plan-params" summary="core plan" class="ul-li-view-edit table stripes no-margin border-common">
            <li class="head"  [ngClass]="{'pending-status-bg':isPending}">
              <div class="col-sm-12 padding-left">
                <b>Core Plan Information</b>
              </div>
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">
                This Plan*
              </div>
              <div
                class="col-sm-8 padding-left flex-column"
                *ngIf="planDetailsData?.userTypeID === riaUserId || isAddMode"
              >
                <div class="flex-row">
                  <span
                    ><input type="radio" formControlName="planInfo" name="planInfo" [value]="0" />already
                    <b>EXISTS</b> at Nationwide</span
                  >
                  <span class="ml-10"
                    ><input type="radio" formControlName="planInfo" name="planInfo" [value]="1" />is a
                    <b>NEW Start-up</b> plan </span
                  ><span class="ml-10"
                    ><input type="radio" formControlName="planInfo" name="planInfo" [value]="2" />is a
                    <b>NEW Takeover</b> Plan</span
                  >
                </div>
                <div>
                  <nfrp-inline-error
                    *ngIf="(corePlanControl.planInfo.touched || submitted) && corePlanControl.planInfo.errors?.required"
                    errorId="edit-core-plan-this-valid-error"
                    [message]="'Is this plan new to or existing with Nationwide?'"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
              <ng-container
                *ngIf="
                  planDetailsData?.userTypeID === tpaUserId ||
                  planDetailsData?.userTypeID === tpaTeam ||
                  planDetailsData?.userTypeID === nbtTeam
                "
              >
                <li>
                  <span *ngIf="planDetailsData?.planNewToProviderNo === 0"
                    ><input type="radio" formControlName="planInfo" name="planInfo" [value]="0" />already
                    <b>EXISTS</b> at Nationwide</span
                  >
                  <span *ngIf="planDetailsData?.planNewToProviderNo === 1" class="ml-10"
                    ><input type="radio" formControlName="planInfo" name="planInfo" [value]="1" />is a
                    <b>NEW Start-up</b> plan </span
                  ><span class="ml-10" *ngIf="planDetailsData?.planNewToProviderNo === 2"
                    ><input type="radio" formControlName="planInfo" name="planInfo" [value]="2" />is a
                    <b>NEW Takeover</b> Plan</span
                  >
                </li></ng-container
              >
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">Plan Type*</div>
              <div class="col-sm-8 padding-left">
                <select
                  id="PLAN_TYPE_ID"
                  formControlName="planType"
                  name="planType"
                  [ngClass]="{
                    'has-error': isControlInvalid('planType')
                  }"
                  tabindex="3"
                  title="Plan Type"
                  (change)="getSelectedPlanType($event.target.value)"
                >
                  <option [ngValue]="null">--- Select from list ---</option>
                  <option *ngFor="let planType of planTypeList | orderBy: 'planLabel'" [value]="planType.planTypeId"
                    >{{ planType.planLabel }}
                  </option>
                </select>
                <span class="ml-20">
                  <nfrp-inline-error
                    *ngIf="(corePlanControl.planType.touched || submitted) && corePlanControl.planType.errors?.required"
                    errorId="edit-core-plan-plan-type-valid-error"
                    [message]="getRequiredDropdownErrorMessage('Plan Type')"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">Preferred Plan Administrator*</div>
              <div class="col-sm-8 padding-left" *ngIf="!isTpaUser">
                <select
                  class="tpa-max-width"
                  formControlName="tpAdminNbr"
                  name="tpAdminNbr"
                  id="TPADMIN_NBR"
                  tabindex="4"
                  [ngClass]="{
                    'has-error': isControlInvalid('tpAdminNbr')
                  }"
                  title="TPA"
                  (change)="getSelectedTPA($event.target.value)"
                >
                  <option [ngValue]="null">--- Select from list ---</option>
                  <ng-container *ngIf="showTpaActive">
                    <option *ngFor="let tpa of tpaList | orderBy: 'tpAdminLabel'" [value]="tpa.tpAdminNbr"
                      >{{ tpa.tpAdminNbr }} : {{ tpa.tpAdminLabel }}</option
                    >
                  </ng-container>
                  <ng-container *ngIf="showTpAdminNwList">
                    <option *ngFor="let tpa of nwTpaList | orderBy: 'tpAdminLabel'" [value]="tpa.tpAdminNbr"
                      >{{ tpa.tpAdminNbr }} : {{ tpa.tpAdminLabel }}</option
                    >
                  </ng-container>
                </select>
                <span class="ml-20">
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.tpAdminNbr.touched || submitted) && corePlanControl.tpAdminNbr.errors?.required
                    "
                    errorId="edit-core-plan-tpadmin-type-valid-error"
                    [message]="getRequiredDropdownErrorMessage('Preferred Plan Administrator')"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
              <div class="col-sm-8 padding-left" *ngIf="isTpaUser">{{ selectedTPA }} : {{ tpaFirmName }}</div>
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">Money Manager*</div>
              <div class="col-sm-8 padding-left">
                <select
                  formControlName="mnyMgrId"
                  name="mnyMgrId"
                  id="MNY_MGR_ID"
                  tabindex="5"
                  title="Money Manager"
                  [ngClass]="{
                    'has-error': isControlInvalid('mnyMgrId')
                  }"
                  (change)="checkStaditionPlanToMM()"
                >
                  <option [ngValue]="null">--- Select from list ---</option>
                  <ng-container *ngIf="!showWFMM">
                    <option
                      *ngFor="let moneyManager of moneyManagerList | orderBy: 'moneyManagerLabel'; let i = index"
                      [value]="moneyManager.moneyManagerId"
                      >{{ moneyManager.moneyManagerLabel }} {{ moneyManager.wallOff ? '(W)' : '' }}
                    </option>
                  </ng-container>
                  <ng-container *ngIf="showWFMM">
                    <option
                      *ngFor="let moneyManager of mmNonWalledOffList | orderBy: 'moneyManagerLabel'; let i = index"
                      [value]="moneyManager.moneyManagerId"
                      >{{ moneyManager.moneyManagerLabel }}
                    </option>
                  </ng-container>
                </select>
                <span class="ml-20">
                  <nfrp-inline-error
                    *ngIf="(corePlanControl.mnyMgrId.touched || submitted) && corePlanControl.mnyMgrId.errors?.required"
                    errorId="edit-core-plan-mnymgrid-valid-error"
                    [message]="getRequiredDropdownErrorMessage('Money Manager')"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">Product*</div>
              <div class="col-sm-8 padding-left">
                <select 
                  [class.disabled]="!selectedMoneyMgrId" formControlName="providerProductId" name="providerProductId"
                  id="PROVIDER_PRODUCT_ID" tabindex="2" 
                  [ngClass]="{ 'has-error': isControlInvalid('providerProductId')}" 
                  title="Provider Product" (change)="getSelectedProduct($event.target.value)"
                  >
                  <option [ngValue]="null">--- Select from list ---</option>
                  <option 
                    *ngFor="let product of productsList | orderBy: 'providerProductLabel'"
                    [value]="product.providerProductId">{{ product.providerProductLabel }}
                  </option>
                </select>
                <span class="ml-20">
                  <nfrp-inline-error 
                    *ngIf="(corePlanControl.providerProductId.touched || submitted) && corePlanControl.providerProductId.errors?.required" 
                    errorId="edit-core-plan-product-valid-error"
                    [message]="getRequiredDropdownErrorMessage('Provider’s Product')">
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li *ngIf="!hideCustodianORTrustee">
              <div class="col-sm-4 padding-left unread-font-weight">Custodian/Trustee</div>
              <div class="col-sm-8 padding-left">
                <input type="radio" formControlName="custodianOrTrustee" [value]="'Custodian'" name="custodianOrTrustee" />Custodian
                <span class="ml-10"
                  ><input type="radio" formControlName="custodianOrTrustee" [value]="'Trustee'" name="custodianOrTrustee" />Trustee</span
                >
                <span class="ml-20 mt-6">
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.custodianOrTrustee.touched || submitted) &&
                      corePlanControl.custodianOrTrustee.errors?.required
                    "
                    errorId="edit-core-plan-provider-map-valid-error"
                    [message]="getRequiredDropdownErrorMessage('Custodian/Trustee')"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li
              *ngIf="
                selectedMoneyMgrId === clsInvestments ||
                selectedMoneyMgrId === portFolioStrategies ||
                selectedMoneyMgrId === meederAdvisoryServices ||
                selectedMoneyMgrId === stadionMnyMgrId
              "
            >
              <div class="col-sm-4 padding-left unread-font-weight">Money Manager Options<span *ngIf="selectedMoneyMgrId !== stadionMnyMgrId">*</span></div>
              <div class="col-sm-8 padding-left">
                <div id="stadionOp" *ngIf="selectedMoneyMgrId === stadionMnyMgrId">
                  <div class="margin-lr-top-5">
                    Would you like to use the Core Fund Strategy?*
                    <input
                      type="radio"
                      formControlName="isCoreFundStrategy"
                      name="isCoreFundStrategy"
                      [value]="false"
                      required
                      (change)="onCoreFundStrategyChange(false)"
                    />No
                    <input
                      type="radio"
                      formControlName="isCoreFundStrategy"
                      name="isCoreFundStrategy"
                      [value]="true"
                      (change)="onCoreFundStrategyChange(true)"
                      required
                    />Yes
                    <span class="ml-20">
                      <nfrp-inline-error
                        *ngIf="
                          (corePlanControl.isCoreFundStrategy.touched || submitted) &&
                          corePlanControl.isCoreFundStrategy.errors?.required
                        "
                        errorId="edit-core-plan-fund-strategy-valid-error"
                        [message]="getRequiredErrorMessage('Core Fund Strategy')"
                      >
                      </nfrp-inline-error>
                    </span>
                  </div>
                  <div class="standionPlanOpt" [class.disabled]="!isCoreFundSelected && isAddMode">
                    Please Select a Stadion plan option*:
                    <input
                      type="radio"
                      formControlName="stadionPlanOption"
                      name="stadionPlanOption"
                      [value]="false"
                      id="standition-choice"
                    />Storyline with Participant Choice &nbsp;&nbsp;<input
                      type="radio"
                      formControlName="stadionPlanOption"
                      name="stadionPlanOption"
                      id="storyline-qdia"
                      [value]="true"
                    />Storyline with QDIA
                    <span class="ml-20">
                      <nfrp-inline-error
                        *ngIf="
                          (corePlanControl.stadionPlanOption.touched || submitted) &&
                          corePlanControl.stadionPlanOption.errors?.required
                        "
                        errorId="edit-core-plan-stadion-plan-valid-error"
                        [message]="'Please select a Stadion StoryLine Option'"
                      >
                      </nfrp-inline-error>
                    </span>
                  </div>
                  <div class="standionPlanOpt">
                    Please enter your Stadion Participant Code:
                    <input
                      type="text"
                      formControlName="stadionPartipantCode"
                      [ngClass]="{
                        'has-error': isControlInvalid('stadionPartipantCode')
                      }"
                      name="stadionPartipantCode"
                    />
                    <span class="ml-20">
                      <nfrp-inline-error
                        *ngIf="
                          (corePlanControl.stadionPartipantCode.touched || submitted) &&
                          corePlanControl.stadionPartipantCode.errors?.required
                        "
                        errorId="edit-core-plan-stadion-code-valid-error"
                        [message]="getRequiredErrorMessage('Stadion Participant Code')"
                      >
                      </nfrp-inline-error>
                      <nfrp-inline-error
                        *ngIf="
                          (corePlanControl.stadionPartipantCode.touched || submitted) &&
                          corePlanControl.stadionPartipantCode.errors?.pattern
                        "
                        errorId="edit-core-plan-stadion-code-valid-error"
                        [message]="getValidErrorMessage('Stadion Participant Code')"
                      >
                      </nfrp-inline-error>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="
                    selectedMoneyMgrId === clsInvestments ||
                    selectedMoneyMgrId === portFolioStrategies ||
                    selectedMoneyMgrId === meederAdvisoryServices
                  "
                >
                  <span class="ml-20">
                    <nfrp-inline-error
                      *ngIf="
                        (corePlanControl.mnyMgrProgramCode.touched || submitted) &&
                        corePlanControl.mnyMgrProgramCode.errors?.required
                      "
                      errorId="edit-core-plan-cls-option-code-valid-error"
                      [message]="getRequiredErrorMessage('Money Manager Program Option')"
                    >
                    </nfrp-inline-error>
                  </span>
                  <ng-container *ngIf="showOldMM && selectedMoneyMgrId === clsInvestments">
                    <ng-container *ngIf="!showAdvisorOnly">
                      <div>
                        <input
                          id="input-radio-1"
                          type="radio"
                          formControlName="mnyMgrProgramCode"
                          name="mnyMgrProgramCode"
                          value="iam"
                          (change)="mnyMgrProgramCodeChangeEvent($event, 'iam')"
                        />Individualized Account Management
                      </div>
                      <div>
                        <input
                          id="input-radio-2"
                          type="radio"
                          formControlName="mnyMgrProgramCode"
                          name="mnyMgrProgramCode"
                          value="cpm"
                          (change)="mnyMgrProgramCodeChangeEvent($event, 'cpm')"
                        />CPM
                      </div>
                      OR
                    </ng-container>

                    <div>
                      <input
                        id="input-radio-3"
                        type="radio"
                        formControlName="mnyMgrProgramCode"
                        name="mnyMgrProgramCode"
                        value="adv1core"
                        (change)="mnyMgrProgramCodeChangeEvent($event, 'adv1core')"
                      />Advisor One
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      (!showOldMM && selectedMoneyMgrId === clsInvestments) ||
                      selectedMoneyMgrId === portFolioStrategies ||
                      selectedMoneyMgrId === meederAdvisoryServices
                    "
                  >
                    <div *ngFor="let mnyMgrPgm of moneyMgrPgmDetails; let i = index">
                      <label for="mny_mgr_{{ mnyMgrPgm.mnyMgrProgramLabel }}">
                        <input
                          id="input-radio-{{ i }}"
                          type="radio"
                          formControlName="mnyMgrProgramCode"
                          name="mnyMgrProgramCode"
                          [value]="mnyMgrPgm?.mnyMgrId?.mnyMgrProgramCode"
                        />
                        {{ mnyMgrPgm.mnyMgrProgramLabel }}
                      </label>
                    </div>
                  </ng-container>
                </div>
              </div>
            </li>
            <li class="padding" *ngIf="selectedMoneyMgrId === clsInvestments">
              <div class="stadionMMP padding-left">
                <div class="planAssets unread-font-weight padding-left">
                  If your plan’s co-advisory firm appears in the list below, please select the appropriate radial
                  button. If not, please select “Not Listed”.
                </div>
                <ul class="ul-li-view-edit table stripes no-margin border-common">
                  <span class="ml-20">
                    <nfrp-inline-error
                      *ngIf="submitted && corePlanControl.solicitorFirm.errors?.required"
                      errorId="edit-core-plan-cls-option-code-valid-error"
                      [message]="'Please Select a co-advisory Firm'"
                    >
                    </nfrp-inline-error>
                  </span>
                  <li>
                    <div class="flex-column">
                      <div
                        class="mnyMgrOptions padding-left"
                        *ngFor="
                          let coAdvisory of coAdvisoryList | orderBy: 'coAdvisory.coadvisoryFirmId?.coadvisoryId';
                          let i = index
                        "
                      >
                        <input
                          type="radio"
                          id="radio-{{ i }}"
                          name="solicitorFirm"
                          [value]="coAdvisory.coadvisoryFirmId"
                          formControlName="solicitorFirm"
                        />{{ coAdvisory.coadvisoryFirmLabel }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">Initial Assets*</div>
              <div class="col-sm-8 padding-left flex-column">
                <div class="width-100">
                  <div class="planAssets">
                    <span class="unread-font-weight"
                      >Retrieve from PLUS and Enter the following Plan Asset Information:</span
                    >
                    <br /><br />Existing Plans - Retirement Plan's Current Assets <br /><br />Takeover Plans - Sum of
                    transferring assets and 1st year annual asset flow <br /><br />New/Start-Up Plans - 1st year annual
                    asset flow
                  </div>

                  <br /><br />
                  <div class="flex-row">
                    <label for="planAssets" class="req"
                      >Initial Assets<label
                        >:
                        <input
                          type="number"
                          formControlName="planAssets"
                          maxlength="22"
                          name="planAssets"
                          [ngClass]="{
                            'has-error': isControlInvalid('planAssets')
                          }"
                          id="planAssets" /></label
                    ></label>
                    <span class="ml-20">
                      <nfrp-inline-error
                        *ngIf="
                          (corePlanControl.planAssets.touched || submitted) &&
                          corePlanControl.planAssets.errors?.required
                        "
                        errorId="edit-core-plan-plan-assets-valid-error"
                        [message]="getRequiredInputErrorMessage('Plans Initial Assets')"
                      >
                      </nfrp-inline-error>
                      <nfrp-inline-error
                        *ngIf="
                          (corePlanControl.planAssets.touched || submitted) &&
                          corePlanControl.planAssets.errors?.maxLength
                        "
                        errorId="edit-core-plan-plan-assets-valid-error"
                        [message]="getValidErrorMessage('Initial Assets')"
                      >
                      </nfrp-inline-error>
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">Plan Document Print Access*</div>
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-lg-12 col-sm-12">
                    <div class="col-lg-5 col-sm-6">
                      Allow Document Printing?
                    </div>
                    <div class="col-lg-1 col-sm-2">
                      <input id="print-access-radio-no" type="radio" formControlName="isPrintAccess" name="isPrintAccess" [value]="0" />          
                      <label for="print-access-radio-no" id="print-access-radio-no-label"> No </label>
                    </div>
                    <div class="col-lg-1 col-sm-2">
                      <input id="print-access-radio-yes" type="radio" formControlName="isPrintAccess" name="isPrintAccess" [value]="1" />
                      <label for="print-access-radio-yes" id="print-access-radio-yes-label"> Yes </label>
                    </div>                    
                    <nfrp-inline-error
                      *ngIf="
                        (corePlanControl.isPrintAccess.touched || submitted) &&
                        corePlanControl.isPrintAccess.errors?.required
                      "
                      errorId="edit-core-plan-print-access-valid-error"
                      [message]="getRequiredDropdownErrorMessage('Allow Document Printing')"
                    >
                    </nfrp-inline-error>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="col-lg-5 col-sm-6">
                      Allow Manager to Print Enrollments?
                    </div>
                    <div class="col-lg-1 col-sm-2">
                      <input id="print-enroll-radio-no" type="radio" formControlName="isPrintEnrollment" name="isPrintEnrollment" [value]="0" />          
                      <label for="sprint-access-radio-no" id="print-enroll-radio-no-label"> No </label>
                    </div>
                    <div class="col-lg-1 col-sm-2">
                      <input id="print-enroll-radio-yes" type="radio" formControlName="isPrintEnrollment" [value]="0" name="isPrintEnrollment" [value]="1" />
                      <label for="print-enroll-radio-yes" id="aprint-enroll-radio-yes-label"> Yes </label>
                    </div>                    
                    <nfrp-inline-error
                      *ngIf="
                        (corePlanControl.isPrintEnrollment.touched || submitted) &&
                        corePlanControl.isPrintEnrollment.errors?.required
                      "
                      errorId="edit-core-plan-print-enrollment-valid-error"
                      [message]="getRequiredDropdownErrorMessage('Allow Manager to Print Enrollments')"
                    >
                    </nfrp-inline-error>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="col-lg-5 col-sm-6">
                      Merrill Booklet Access?
                    </div>
                    <div class="col-lg-1 col-sm-2">
                      <input id="merill-access-radio-no" type="radio" formControlName="isMerillAccess" name="isMerillAccess" [value]="0" />          
                      <label for="merill-access-radio-no" id="merill-access--radio-no-label"> No </label>
                    </div>
                    <div class="col-lg-1 col-sm-2">
                      <input id="merill-access-radio-yes" type="radio" formControlName="isMerillAccess" [value]="1" name="isMerillAccess" />
                      <label for="merill-access-radio-yes" id="merill-access--radio-yes-label"> Yes </label>
                    </div>                   
                    <nfrp-inline-error
                      *ngIf="
                        (corePlanControl.isMerillAccess.touched || submitted) &&
                        corePlanControl.isMerillAccess.errors?.required
                      "
                      errorId="edit-core-plan-print-enrollment-valid-error"
                      [message]="getRequiredDropdownErrorMessage('Merrill Booklet Access')"
                    >
                    </nfrp-inline-error>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <div class="col-sm-4 padding-left unread-font-weight">Plan Name*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  formControlName="planName"
                  [ngClass]="{
                    'has-error': isControlInvalid('planName')
                  }"
                  name="planName"
                  size="50"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="(corePlanControl.planName.touched || submitted) && corePlanControl.planName.errors?.required"
                    errorId="edit-core-plan-plan-name-valid-error"
                    [message]="getRequiredInputErrorMessage('Plan Name')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>

            <li>
              <div class="col-sm-4 padding-left unread-font-weight">
                Case Number*
                <nfrp-help-icon
                  message="The Nationwide plan case number format is TPA-00000. You only need to type the last five digits."
                  idLabel="case-number"
                ></nfrp-help-icon>
              </div>
              <div class="col-sm-8 padding-left">
                <input type="text" size="3" [value]="selectedTPA" [class.disabled]="true" />
                <span class="text-center case-num-text">-</span>
                <span
                  ><input
                    type="text"
                    formControlName="caseNumber"
                    name="caseNumber"
                    [ngClass]="{
                      'has-error': isControlInvalid('caseNumber')
                    }"
                    maxlength="5"
                    minlength="5"
                    size="10"
                /></span>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.caseNumber.touched || submitted) && corePlanControl.caseNumber.errors?.required
                    "
                    errorId="edit-core-plan-case-number-valid-error"
                    [message]="getRequiredInputErrorMessage('Plan Case Number')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.caseNumber.touched || submitted) &&
                      (corePlanControl.caseNumber.errors?.maxlength || corePlanControl.caseNumber.errors?.minlength)
                    "
                    errorId="edit-core-plan-case-valid-error"
                    [message]="getValidErrorMessage('Plan Case Number')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.caseNumber.touched || submitted) && corePlanControl.caseNumber.errors?.pattern
                    "
                    errorId="edit-core-plan-case2-valid-error"
                    [message]="getValidErrorMessage('Plan Case Number')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">
                EIN<span *ngIf="selectedMoneyMgrId === stadionMnyMgrId">*</span>
                <nfrp-help-icon
                  message="The Employee Identification Number (EIN) format is 00-0000000. Only Numbers are allowed."
                  idLabel="ein-number"
                ></nfrp-help-icon>
              </div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  formControlName="ein"
                  name="ein"
                  [ngClass]="{
                    'has-error': isControlInvalid('ein')
                  }"
                  size="15"
                  mask="00-0000000"
                  [patterns]="numberMaskPattern"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="(corePlanControl.ein.touched || submitted) && corePlanControl.ein.errors?.required"
                    errorId="edit-core-plan-ein-valid-error"
                    [message]="getRequiredInputErrorMessage('Plan EIN')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="(corePlanControl.ein.touched || submitted) && corePlanControl.ein.errors?.maxLength"
                    errorId="edit-core-plan-ein1-valid-error"
                    [message]="getValidErrorMessage('Plan EIN')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">
                Trust Tax ID
              </div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  formControlName="trustTaxId"
                  [ngClass]="{
                    'has-error': isControlInvalid('trustTaxId')
                  }"
                  name="trustTaxId"
                  mask="00-0000000"
                  [patterns]="numberMaskPattern"
                  size="15"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.trustTaxId.touched || submitted) && corePlanControl.trustTaxId.errors?.required
                    "
                    errorId="edit-core-plan-trust-taxid-valid-error"
                    [message]="getRequiredInputErrorMessage('Trust Tax ID')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.trustTaxId.touched || submitted) && corePlanControl.trustTaxId.errors?.maxLength
                    "
                    errorId="edit-core-plan-trustTaxId-valid-error"
                    [message]="getValidErrorMessage('Trust Tax ID')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-4 padding-left unread-font-weight">
                Plan Number<span *ngIf="selectedMoneyMgrId === stadionMnyMgrId">*</span>
                <nfrp-help-icon
                  message="Plan Number requested is located in the Legal Plan Document. This identifier is used when more than one type of
                  retirement plan is offered by the Plan Sponsor and is required for the use of Stadion's Collective Investment Funds (CIFs).
                  "
                  idLabel="plan-number"
                ></nfrp-help-icon>
              </div>
              <div class="col-sm-8 padding-left">
                <select
                  formControlName="planNumber"
                  name="planNumber"
                  tabindex="10"
                  title="Plan Number"
                  [ngClass]="{
                    'has-error': isControlInvalid('planNumber')
                  }"
                  id="Plan_Nbr"
                >
                  <option [ngValue]="null">---</option>
                  <option value="1">001</option>
                  <option value="2">002</option>
                  <option value="3">003</option>
                  <option value="4">004</option>
                  <option value="5">005</option>
                  <option value="6">006</option>
                  <option value="7">007</option>
                  <option value="8">008</option>
                  <option value="9">009</option>
                  <option value="10">010</option>
                </select>
                <span class="ml-20">
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.planNumber.touched || submitted) && corePlanControl.planNumber.errors?.required
                    "
                    errorId="edit-core-plan-plan-number-valid-error"
                    [message]="getRequiredDropdownErrorMessage('Plan Number')"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li *ngIf="showConversion">
              <div class="col-sm-4 padding-left unread-font-weight">Conversion*</div>
              <div class="col-sm-8 padding-left">
                <input type="radio" formControlName="conversion" [value]="0" name="conversion" />No
                <span class="ml-10"
                  ><input type="radio" formControlName="conversion" [value]="1" name="conversion" />Yes</span
                >
                <span class="ml-20 mt-6">
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.conversion.touched || submitted) && corePlanControl.conversion.errors?.required
                    "
                    errorId="edit-core-plan-conversion-map-valid-error"
                    [message]="getRequiredErrorMessage('Conversion')"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li *ngIf="showManagerMapping">
              <div class="col-sm-4 padding-left unread-font-weight">
                Manager Mapping*
                <nfrp-help-icon
                  message="This feature can only be utilized if the plan is moving from one RIA Money Manager firm to another. Only participants currently utilizing MAS will be mapped to the new manager."
                  idLabel="manager-mapping"
                ></nfrp-help-icon>
              </div>
              <div class="col-sm-8 padding-left">
                <input type="radio" formControlName="managerMapping" [value]="0" name="managerMapping" />No
                <span class="ml-10"
                  ><input type="radio" formControlName="managerMapping" [value]="1" name="managerMapping" />Yes</span
                >
                <span class="ml-20 mt-6">
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.managerMapping.touched || submitted) &&
                      corePlanControl.managerMapping.errors?.required
                    "
                    errorId="edit-core-plan-managerMapping-map-valid-error"
                    [message]="getRequiredErrorMessage('Manager Mapping')"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li *ngIf="showProviderMapping">
              <div class="col-sm-4 padding-left unread-font-weight">Provider Mapping?*</div>
              <div class="col-sm-8 padding-left">
                <input type="radio" formControlName="providerMapping" [value]="0" name="providerMapping" />No
                <span class="ml-10"
                  ><input type="radio" formControlName="providerMapping" [value]="1" name="providerMapping" />Yes</span
                >
                <span class="ml-20 mt-6">
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.providerMapping.touched || submitted) &&
                      corePlanControl.providerMapping.errors?.required
                    "
                    errorId="edit-core-plan-provider-map-valid-error"
                    [message]="'Does this plan use Provider Mapping?'"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li *ngIf="selectedMoneyMgrId !== stadionMnyMgrId && showAutoMgmt">
              <div class="col-sm-4 padding-left unread-font-weight">
                Auto-Management?*
                <nfrp-help-icon
                  message="This feature can be used to default enroll all plan participants into the managed accounts program. When selected, this process will require all participants that wish to opt out of the manangement service to complete an opt out form."
                  idLabel="manager-mapping"
                ></nfrp-help-icon>
              </div>
              <div class="col-sm-8 padding-left">
                <input type="radio" formControlName="autoManagement" [value]="0" name="autoManagement" />No
                <span class="ml-10"
                  ><input type="radio" formControlName="autoManagement" [value]="1" name="autoManagement" />Yes</span
                >
                <span class="ml-20 mt-6">
                  <nfrp-inline-error
                    *ngIf="
                      (corePlanControl.autoManagement.touched || submitted) &&
                      corePlanControl.autoManagement.errors?.required
                    "
                    errorId="edit-core-plan-auto-mgmt-valid-error"
                    [message]="getRequiredErrorMessage('Auto-Management')"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li *ngIf="selectedMoneyMgrId !== stadionMnyMgrId && showQdia">
              <div class="col-sm-4 padding-left unread-font-weight">QDIA?*</div>
              <div class="col-sm-8 padding-left">
                <input type="radio" formControlName="qdia" name="qdia" [value]="0" />No
                <span class="ml-10"><input type="radio" formControlName="qdia" name="qdia" [value]="1" />Yes</span>
                <span class="ml-20 mt-6">
                  <nfrp-inline-error
                    *ngIf="(corePlanControl.qdia.touched || submitted) && corePlanControl.qdia.errors?.required"
                    errorId="edit-core-plan-qdia-valid-error"
                    [message]="getRequiredErrorMessage('QDIA')"
                  >
                  </nfrp-inline-error>
                </span>
              </div>
            </li>
            <li *ngIf="!isAddMode">
              <div class="col-sm-4 padding-left unread-font-weight">Active?</div>
              <div class="col-sm-8 padding-left">
                {{ planDetailsData?.status }}
              </div>
            </li>           
          </ul>
          <br />
          <div class="row">
            <div class="col-sm-1 padding-left" *ngIf="!isAddMode">
              <input
                id="search-button"
                class="button-primary button hover-right margin-right"
                type="submit"
                value="Edit"
              />
            </div>           
            <div class="col-sm-2 padding-left" *ngIf="isAddMode">
              <input
                id="search-button"
                class="button-primary button hover-right margin-right"
                type="submit"
                value="Create Plan"
              />
            </div>
            <div [ngClass]="{ 'col-sm-9 no-padding': isAddMode, 'col-sm-4 no-padding': !isAddMode }"></div>
            <div class="col-sm-1 no-padding">
              <input
                type="button"
                class="button button-secondary hover-left margin-right"
                (click)="cancel()"
                value="Cancel"
              />
            </div>
            <div class="col-sm-4 no-padding" *ngIf="!isAddMode && !showPendingButtons"></div>
            <div class="col-sm-3" *ngIf="!isAddMode && showPendingButtons">
              <input
                id="search-button"
                class="button-primary button hover-right margin-right"
                type="button"
                (click)="pendingButtonClick('clear')"
                value="Clear All Pending Changes"
              />
            </div>
            <div class="col-sm-3" *ngIf="!isAddMode && showPendingButtons && !hideActivatePendingButton">
              <input
                id="search-button"
                class="button-primary button hover-right margin-right"
                type="button"
                (click)="pendingButtonClick('activate')"
                value="Activate Pending Changes"
              />
            </div>
            <div
              class="col-sm-2 no-padding"
              *ngIf="
                planDetailsData?.userTypeID === riaUserId && planDetailsData?.status === 'Pending'">
              <input
                id="search-button"
                class="button-primary button hover-right margin-right"
                type="button"
                size="40"
                (click)="saveActivateInIk()"
                value="{{ activateTrackIkButton }}"
              />
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container>
        <nfrp-default-modal
          [message]="message"
          yesButtonValue="Okay"
          [showModal]="showModal"
          (yesClicked)="showModal = false"
        >
        </nfrp-default-modal>
        <nfrp-default-modal
          [header]="modalOptions.header"
          [message]="modalOptions.message"
          [noButtonValue]="modalOptions.noButtonValue"
          [yesButtonValue]="modalOptions.yesButtonValue"
          [showModal]="modalOptions.showClsModal"
          (noClicked)="noClicked()"
          (yesClicked)="yesClicked()"
        >
        </nfrp-default-modal>
        <nfrp-default-modal
        [header]="pendingChangesModel.header"
        [message]="pendingChangesModel.message"
        [noButtonValue]="pendingChangesModel.noButtonValue"
        [yesButtonValue]="pendingChangesModel.yesButtonValue"
        [showModal]="pendingChangesModel.showClsModal"
        (noClicked)="noBtn()"
        (yesClicked)="yesBtn()"
      >
        </nfrp-default-modal>
      </ng-container>
    </div>
  </div>
</section>
<nfrp-navigate-away-modal></nfrp-navigate-away-modal>
